import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

export const GlobalStyles = createGlobalStyle`
  ${normalize}

  *, *::after, *::before {
    box-sizing: border-box;
  }

  *, *:focus-visible, *:focus-visible {
    outline: none;
  }

  html, body, #root {
    height: 100%;
  }

  body, #root {
    display: flex;
    flex-direction: column;
  }

  body {
    background: #F5F6FA;
  }

  html {
    font-size: 62.5%;
  }

  body {
    font-family: 'Inter', sans-serif;
    font-size: 1.6rem;
    font-weight: normal;
    line-height: 1.5;
  }

  .body-to-print {
    padding: 10rem;
  }

  .hide-body-y-scroll {
    overflow-y: hidden;
  }
`;
