if (window.location.hostname.match(/businessv2.usend.com/gi)) {
  window.location.replace('https://businessv2.us.inter.co');
}

if (window.location.hostname.match(/businessv2-dev.usend.com/gi)) {
  window.location.replace('https://businessv2.uatbi.com.br');
}

const IS_DEV = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

window.our_domain = IS_DEV
  ? ''
  : window.location.origin.match(/business.us.inter.co/gi)
  ? 'inter.co'
  : window.location.origin.match(/business.uatbi.com.br/gi)
  ? 'uatbi.com.br'
  : 'usend.com';

function getDomain() {
  const windowLocationOrigin = window.location.origin;

  if (IS_DEV) {
    window.v1_domain = 'http://localhost:3001';
    window.v2_domain = 'http://localhost:3000';
  }

  if (windowLocationOrigin.match(/business-dev.usend|businessv2-dev.usend/gi)) {
    window.v1_domain = 'https://business-dev.usend.com';
    window.v2_domain = 'https://businessv2-dev.usend.com';
  }

  if (windowLocationOrigin.match(/business.uatbi|businessv2.uatbi/gi)) {
    window.v1_domain = 'https://business.uatbi.com.br';
    window.v2_domain = 'https://businessv2.uatbi.com.br';
  }

  if (windowLocationOrigin.match(/business.usend|businessv2.usend/gi)) {
    window.v1_domain = 'https://business.usend.com';
    window.v2_domain = 'https://businessv2.usend.com';
  }

  if (windowLocationOrigin.match(/business.us.inter.co|businessv2.us.inter.co/gi)) {
    window.v1_domain = 'https://business.us.inter.co';
    window.v2_domain = 'https://businessv2.us.inter.co';
  }
}

getDomain();
