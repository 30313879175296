import i18n from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import enUS from './locales/en-US.json';
import ptBR from './locales/pt-BR.json';

export const resources = {
  'en-US': {
    translation: enUS,
  },
  'pt-BR': {
    translation: ptBR,
  },
} as const;

i18n
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    initImmediate: false,
    detection: {
      caches: ['cookie'],
      order: ['cookie', 'navigator'],
    },
    resources,
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false,
    },
    debug: false,
  });

export { i18n };
