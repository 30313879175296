import { useCookie } from 'hooks/use-cookie';
import * as React from 'react';

enum ActionTypes {
  'DO_LOGIN' = 'DO_LOGIN',
  'DO_LOGOUT' = 'DO_LOGOUT',
}

type Actions =
  | { type: typeof ActionTypes.DO_LOGIN; payload: string }
  | { type: typeof ActionTypes.DO_LOGOUT };

type Dispatch = React.Dispatch<Actions>;

type State = {
  token: string;
};

const initialState: State = {
  token: '',
};

type ContextType = { state: State; dispatch: Dispatch };

const AuthContext = React.createContext<ContextType>({
  state: initialState,
  dispatch: () => undefined,
});

AuthContext.displayName = 'AuthContext';

type AuthProviderProps = {
  children: React.ReactNode;
  initialValues?: State;
};

function AuthProvider({ initialValues = initialState, ...rest }: AuthProviderProps) {
  const { setCookie, removeCookie } = useCookie(`${process.env.REACT_APP_ENV}-token`);

  const [state, dispatch] = React.useReducer((state: State, action: Actions) => {
    switch (action.type) {
      case ActionTypes.DO_LOGIN: {
        setCookie(action.payload, {
          domain: window.our_domain,
        });
        return {
          ...state,
          token: action.payload,
        };
      }

      case ActionTypes.DO_LOGOUT: {
        removeCookie({ domain: window.our_domain });
        window.location.href = `${window.v1_domain}/login`;
        return {
          ...state,
          token: '',
        };
      }

      default:
        return state;
    }
  }, initialValues);

  const value = React.useMemo(() => ({ state, dispatch }), [state]);
  return <AuthContext.Provider value={value} {...rest} />;
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export const login = (token: string, dispatch: Dispatch) =>
  dispatch({ type: ActionTypes.DO_LOGIN, payload: token });

export const logout = (dispatch: Dispatch) => dispatch({ type: ActionTypes.DO_LOGOUT });

export { AuthProvider, useAuth };
