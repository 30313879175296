import './env';
import * as React from 'react';
import { useAuth } from './context/auth-context';

const AuthenticatedApp = React.lazy(
  () => import(/* webpackPrefetch: true */ './authenticated-app'),
);

const UnauthenticatedApp = React.lazy(() => import('./unauthenticated-app'));

function App() {
  const {
    state: { token },
  } = useAuth();

  return (
    <React.Suspense fallback={<div />}>
      {token ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </React.Suspense>
  );
}

export default App;
