import Cookies, { CookieAttributes } from 'js-cookie';
import * as React from 'react';

export function useCookie(key: string, initialValue = '') {
  const [value, setValue] = React.useState(() => Cookies.get(key) || initialValue);

  const setCookie = (value: string, options?: CookieAttributes) => {
    setValue(value);
    Cookies.set(key, value, { ...options, secure: true });
  };

  const removeCookie = (options?: CookieAttributes) => {
    setValue('');
    Cookies.remove(key, options);
  };

  return {
    removeCookie,
    setCookie,
    value,
  };
}

export default useCookie;
