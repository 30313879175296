export const theme = {
  breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px',
  },
  spacings: {
    xxsmall: '4px',
    xsmall: '8px',
    small: '16px',
    medium: '32px',
    large: '',
    xlarge: '',
    xxlarge: '',
  },
  palette: {
    common: {
      white: '#ffffff',
      black: '#000000',
    },
    white: '#ffffff',
    primary: '#07605B',
    primaryLight: '#07605B',
    secondary: '#161616',
    error: '#D80027',
    warning: '#ffb74d',
    orange: '#FF7A00',
    info: '#64b5f6',
    success: '#10AC84',
    grey: {
      '50': '#DEDFE4',
      '100': '#d3d3d3',
      '200': '#b6b6b6',
      '300': '#999999',
      '400': '#838383',
      '500': '#6d6d6d',
      '600': '#656565',
      '700': '#5a5a5a',
      '800': '#505050',
      '900': '#3e3e3e',
    },
    text: {
      primary: '#6d6d6d',
      secondary: '#161616',
      disabled: '#999999',
    },
    divider: '#999999',
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '1.4rem',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightBold: 500,

    h1: {
      fontWeight: 500,
      fontSize: '4rem',
      lineHeight: 1.2,
    },
    h2: {
      fontWeight: 500,
      fontSize: '3.2rem',
      lineHeight: 1.2,
    },
    h3: {
      fontWeight: 500,
      fontSize: '2.1rem',
      lineHeight: 1.2,
    },
    h4: {
      fontWeight: 500,
      fontSize: '1.8rem',
      lineHeight: 1.2,
    },
    h5: {
      fontWeight: 400,
      fontSize: '1.6rem',
      lineHeight: 1.2,
    },
    h6: {
      fontWeight: 500,
      fontSize: '1.4rem',
      lineHeight: 1.5,
    },
    paragraph: {
      fontSize: '1.6rem',
      fontWeight: 300,
      lineHeight: 1.5,
      marginBottom: '1.6rem',
    },
    caption: {
      fontWeight: 300,
      fontSize: '1.2rem',
      lineHeight: 1.5,
    },
  },
  shape: {
    borderRadius: 0.8,
  },
};

export type Theme = typeof theme;
